import { ref } from '@vue/reactivity'
import instance from '@/http-common'

const getData = () => {
  const mainData = ref(null)
  const mainMeta = ref(null)
  const mainLinks = ref(null)
  const mainError = ref(null)
  const loadData = async (params) => {
      try {
          let response = await instance.get('/projects/allProjects', {params:params})
          // if (response.status != 200) {
          //     throw Error('Something Went Wrong!')
          // }
          mainData.value = response.data.data
          mainMeta.value = response.data.meta
          mainLinks.value = response.data.links
          // console.log(mainMeta.value);
          // console.log(response.data.data);
      } catch (err) {
          mainError.value = err.message
        //   console.log(err)
        //   console.log(mainError.value)
      }
  }

  return {mainData, mainMeta, mainLinks, mainError, loadData}
}

export default getData
