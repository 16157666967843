<template>
  <div class="container mt-5 featured-prop">
    <div class="row">
      <h1>FEATURED PROPERTIES</h1>
    </div>
    <!-- <div class="row justify-content-center" v-if="properties && properties.length !== 0">
      <PropertyBox :properties="properties" :imgUrl="imgUrl" />
      <div class="mt-5 view-all">
        <router-link :to="{ name: 'allProperties' }">view all</router-link>
      </div>
    </div>
    <div class="row justify-content-center no-data" v-else-if="properties && properties.length == 0">
      <h2 class="mt-1">Coming Soon</h2>
      <h3 class="mt-3 mb-3 fw-bold">List your property and let us do the work for you</h3>
      <div class="lists">
        <div class="list-sale">
          <router-link :to="{ name: 'sell'}">list for sale</router-link>
        </div>
        <div class="list-rent">
          <router-link :to="{ name: 'rent'}">list for rent</router-link>
        </div>
      </div>
    </div>
    <div class="row justify-content-center" v-else-if="error">
      <div class="col-12">
        <h2 class="error">An Error Happened!</h2>
      </div>
    </div>
    <div class="row justify-content-center" v-else>
      <BoxLoading />
    </div> -->
    
    <div class="row justify-content-center no-data">
      <h2 class="mt-1">Coming Soon</h2>
      <h3 class="mt-3 mb-3 fw-bold">List your property and let us do the work for you</h3>
      <div class="lists">
        <div class="list-sale">
          <router-link :to="{ name: 'sell'}">list for sale</router-link>
        </div>
        <div class="list-rent">
          <router-link :to="{ name: 'rent'}">list for rent</router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<!-- <script>
import getThreeProperties from '@/services/properties/getThreeProperties'
import PropertyBox from "../PropertyBox.vue"
import BoxLoading from "@/components/BoxLoading.vue"
export default {
  components: {
    PropertyBox,
    BoxLoading
  },
  setup() {
    const { mainData:properties, mainError:error, loadData } = getThreeProperties()
    loadData()
    const imgUrl = process.env.VUE_APP_BASE_URL

    return {properties, error, imgUrl}
  }
}
</script> -->

<style scoped>
h1 {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
}
.error {
  color: crimson;
}
.view-all {
  text-align: center;
}
.view-all a {
  text-transform: uppercase;
  background-color: var(--mainColor);
  padding: .3rem .5rem;
  color: #ffffff;
}
.no-data {
  text-align: center;
}
.lists {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.lists .list-sale, .lists .list-rent {
    color: var(--mainColor);
    font-size: 1.5rem;
    text-transform: capitalize;
    border: 1px solid var(--mainColor);
    padding: 1rem;
}

@media (max-width: 575.98px) {
  h1 {
    font-size: 1.3rem;
  }
  
  .no-data h3 {
    font-size: 1rem;
  }
  .lists .list-sale, .lists .list-rent {
    padding: 10px;
    font-size: 17px;
  }
}
</style>