import { ref } from '@vue/reactivity'
import instance from '@/http-common'

const getData = () => {
  const mainData = ref(null)
  const mainError = ref(null)
  const loadData = async (slug) => {
      try {
          let response = await instance.get(`/properties/${slug}`)
          // if (response.status != 200) {
          //     throw Error('Something Went Wrong!')
          // }
          mainData.value = response.data.data
        //   console.log(response.data.data);
      } catch (err) {
          mainError.value = err.message
          // console.log(err)
          // console.log(mainError.value)
      }
  }

  return {mainData, mainError, loadData}
}

export default getData
