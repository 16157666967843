<template>
  <div class="gallery">
    <h2>gallery</h2>
    <div class="box">
      <photo-provider>
        <photo-consumer
          v-for="src in imgList"
          :intro="`${src.project_name}${src.id}`"
          :key="src.id"
          :src="`${baseUrl}${src.images}`"
        >
          <img :src="`${baseUrl}${src.images}`" class="img view-box" />
        </photo-consumer>
      </photo-provider>
    </div>
  </div>
</template>

<script>
export default {
  props: ['imgList', 'baseUrl'],
};
</script>

<style scoped>
.gallery h2 {
  margin-bottom: 1rem;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}
.gallery .box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2rem;
}
.view-box {
  cursor: pointer;
  object-fit: cover;
  height: 300px;
}

@media (max-width: 991.98px) {
  .gallery .box {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
@media (max-width: 767.98px) {
  .gallery .box {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .view-box {
    height: 200px;
  }
}
@media (max-width: 575.98px) {
  .gallery .box {
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  }
  .view-box {
    height: 250px;
  }
}
</style>
