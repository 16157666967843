<template>
  <div class="home">
    <HeaderComponent />
    <FeaturedProjComponent />
    <FeaturedPropComponent />
    <ContactusComponent />
    <PartnersComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderComponent from '@/components/home/HeaderComponent.vue'
import FeaturedPropComponent from '@/components/home/FeaturedPropComponent.vue'
import FeaturedProjComponent from '@/components/home/FeaturedProjComponent.vue'
import ContactusComponent from '@/components/home/ContactusComponent.vue'
import PartnersComponent from '@/components/home/PartnersComponent.vue'
import { useHead } from '@vueuse/head'

export default {
  name: 'HomeView',
  components: {
    HeaderComponent,
    FeaturedPropComponent,
    FeaturedProjComponent,
    ContactusComponent,
    PartnersComponent
  },
  setup() {
    useHead({
      meta: [
        {
          name: `description`,
          content: `Capex Properties includes financial and real estate experts who specialize in finding the best properties and financial options for clients in Dubai and UAE.`
        },
        {
          property: `og:title`,
          content: process.env.VUE_APP_BASE_TITLE
        },
        {
          property: `og:description`,
          content: `Capex Properties includes financial and real estate experts who specialize in finding the best properties and financial options for clients in Dubai and UAE.`
        },
        {
          property: `og:url`,
          content: `https://capexproperties.ae/`
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:site_name`,
          content: process.env.VUE_APP_BASE_TITLE
        },
      ],
      link: [
        {
          rel: `canonical`,
          href: `https://capexproperties.ae/`
        }
      ]
    })
  }
}
</script>

<style>
</style>
