<template>
  <div class="container all-props">
    <div class="row">
      <h2 class="text-center page-title">PROPERTIES</h2>
    </div>
    <div class="filter">
      <p  @click="toggleFilter" :class="{active: showFilter}"><i><FontAwesome icon="filter" /></i> Filter <i class="arrow"><FontAwesome icon="chevron-right" /></i></p>
      <span v-if="readiness">{{readiness}}</span>
      <span v-if="project">Project: {{project.replaceAll('-',' ')}}</span>
      <span v-if="area">Area: {{area.replaceAll('-',' ')}}</span>
      <span v-if="type">{{type}}</span>
      <span v-if="bedrooms">{{bedrooms}} Bedrooms</span>
      <span v-if="bathrooms">{{bathrooms}} Bathrooms</span>
      <span v-if="sort">Sort By: {{sort.replaceAll('_',': ')}}</span>
      <span @click="clearAll" v-if="readiness || project || area || type || bedrooms || bathrooms || sort">X Clear All</span>
    </div>
    <form class="row" :class="{show : showFilter}" @submit.prevent>
      <div class="mb-4 col-md-4">
        <label for="readiness" class="form-label">Status</label>
        <select id="readiness" class="form-select" v-model="readiness" @change="changeReadiness">
          <option value="">All</option>
          <option value="off-plan">Off-Plan Properties</option>
          <option value="completed">Completed Properties</option>
        </select>
      </div>
      <div class="mb-4 col-md-4">
        <label for="project" class="form-label">Project</label>
        <select id="project" class="form-select" v-model="project" @change="changeProject">
          <option value="">All</option>
          <template v-if="projects">
            <option v-for="project in projects" :key="project.project_slug" :value="project.project_slug">{{project.project_name}}</option>
          </template>
          <template v-else>
            <option value="">....</option>
          </template>
        </select>
      </div>
      <div class="mb-4 col-md-4">
        <label for="area" class="form-label">Area</label>
        <select id="area" class="form-select" v-model="area" @change="changeArea">
          <option value="">All</option>
          <template v-if="areas">
            <option v-for="area in areas" :key="area.id" :value="area.slug">{{area.name}}</option>
          </template>
          <template v-else>
            <option value="">....</option>
          </template>
        </select>
      </div>
      <div class="mb-4 col-md-3">
        <label for="type" class="form-label">Type</label>
        <select id="type" class="form-select" v-model="type" @change="changeType">
          <option value="">All</option>
          <template v-if="types">
            <option v-for="oneType in types" :key="oneType.id" :value="oneType.slug">{{oneType.name}}</option>
          </template>
        </select>
      </div>
      <div class="mb-4 col-md-3">
        <label for="bedrooms" class="form-label">Bedrooms</label>
        <input type="number" class="form-control" placeholder="Type bedrooms number" min="0" v-model="bedrooms" @keyup.enter="changeBedrooms" />
      </div>
      <div class="mb-4 col-md-3">
        <label for="bathrooms" class="form-label">Bathrooms</label>
        <input type="number" class="form-control" placeholder="Type bathrooms number" min="0" v-model="bathrooms" @keyup.enter="changeBathrooms" />
      </div>
      <div class="mb-4 col-md-3">
        <label for="sort" class="form-label">Sort By</label>
        <select id="sort" class="form-select" v-model="sort" @change="changeSort">
          <option value="">Default</option>
          <option value="price_high">Price: Highest</option>
          <option value="price_low">Price: Lowest</option>
          <option value="size_big">Size: Biggest</option>
          <option value="size_small">Size: Smallest</option>
        </select>
      </div>
    </form>
    <div class="row">
      <div class="col-12"><h2 class="text-center">No Properties</h2></div>
    </div>
    <!-- <div class="row" v-if="properties" :imgUrl="imgUrl" >
      <div class="col-12" v-if="properties.length === 0"><h2 class="text-center">No Properties</h2></div>
      <PropertyBox :properties="properties" :imgUrl="imgUrl" />
      <ThePagination :mainMeta="mainMeta" :queries="queries" />
    </div>
    <div class="row" v-else-if="propError">
      <div class="col-12">
        <h2 class="error">An Error Happened!</h2>
      </div>
    </div>
    <div class="row" v-else>
      <BoxLoading />
      <BoxLoading />
      <BoxLoading />
      <BoxLoading />
    </div> -->
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useRoute, useRouter } from 'vue-router'
import { watch } from '@vue/runtime-core'
import getProperties from '@/services/properties/getProperties'
import getPropertiesProjects from '@/services/properties/getPropertiesProjects'
import getTypes from '@/services/types/getTypes'
import getAreas from '@/services/areas/getAreas'
// import PropertyBox from "@/components/PropertyBox.vue"
// import ThePagination from '@/components/ThePagination.vue'
// import BoxLoading from "@/components/BoxLoading.vue"
import { useHead } from '@vueuse/head'
export default {
  // components: {
  //   PropertyBox,
  //   BoxLoading,
  //   ThePagination
  // },
  setup() {

    useHead({
      meta: [
        {
          name: `description`,
          content: `Get all the information you need to know about properties for sale to buy or rent in Dubai, UAE. With Capex Properties, you can find all the details related to homes, studios, apartments, villas, mansions or office space to buy or rent in Dubai, UAE.`
        },
        {
          property: `og:title`,
          content: `Properties | ${process.env.VUE_APP_BASE_TITLE}`
        },
        {
          property: `og:description`,
          content: `Get all the information you need to know about properties for sale to buy or rent in Dubai, UAE. With Capex Properties, you can find all the details related to homes, studios, apartments, villas, mansions or office space to buy or rent in Dubai, UAE.`
        },
        {
          property: `og:url`,
          content: `https://capexproperties.ae/properties`
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:site_name`,
          content: process.env.VUE_APP_BASE_TITLE
        },
      ],
      link: [
        {
          rel: `canonical`,
          href: `https://capexproperties.ae/properties`
        }
      ]
    })

    const route = useRoute()
    const router = useRouter()

    const queries = ref(route.query)
    
    const { mainData:areas, mainError:areError, loadData:loadAreas } = getAreas()
    loadAreas()
    
    const { mainData:types, mainError:typesError, loadData:loadTypes } = getTypes()
    loadTypes()

    const { mainData:projects, mainError:projError, loadData:loadProjects } = getPropertiesProjects()
    loadProjects()

    const { mainData:properties, mainError:propError, mainMeta, loadData:loadProperties } = getProperties()
    loadProperties(queries.value)
    
    const imgUrl = process.env.VUE_APP_BASE_URL

    const showFilter = ref(false)

    const readiness = ref('')
    const location = ref('')
    const type = ref('')
    const area = ref('')
    const project = ref('')
    const bedrooms = ref('')
    const bathrooms = ref('')
    const sort = ref('')

    if(route.query.readiness)
    {
      readiness.value = route.query.readiness
    }
    const changeReadiness = () => {
      router.push({ path: '/properties', query: {location: location.value, bathrooms: bathrooms.value, bedrooms: bedrooms.value, project: project.value, type: type.value, readiness: readiness.value, area: area.value, sort: sort.value} })
    }

    if(route.query.type)
    {
      type.value = route.query.type
    }
    const changeType = () => {
      router.push({ path: '/properties', query: {location: location.value, bathrooms: bathrooms.value, bedrooms: bedrooms.value, project: project.value, type: type.value, readiness: readiness.value, area: area.value, sort: sort.value} })
    }

    if(route.query.area)
    {
      area.value = route.query.area
    }
    const changeArea = () => {
      router.push({ path: '/properties', query: {location: location.value,bathrooms: bathrooms.value, bedrooms: bedrooms.value, project: project.value, type: type.value, readiness: readiness.value, area: area.value, sort: sort.value} })
    }

    if(route.query.project)
    {
      project.value = route.query.project
    }
    const changeProject = () => {
      router.push({ path: '/properties', query: {location: location.value, bathrooms: bathrooms.value, bedrooms: bedrooms.value, project: project.value, type: type.value, readiness: readiness.value, area: area.value, sort: sort.value} })
    }

    if(route.query.bedrooms)
    {
      bedrooms.value = route.query.bedrooms
    }
    const changeBedrooms = () => {
      router.push({ path: '/properties', query: {location: location.value, bathrooms: bathrooms.value, bedrooms: bedrooms.value, project: project.value, type: type.value, readiness: readiness.value, area: area.value, sort: sort.value} })
    }

    if(route.query.bathrooms)
    {
      bathrooms.value = route.query.bathrooms
    }
    const changeBathrooms = () => {
      router.push({ path: '/properties', query: {location: location.value, bathrooms: bathrooms.value, bedrooms: bedrooms.value, project: project.value, type: type.value, readiness: readiness.value, area: area.value, sort: sort.value} })
    }

    if(route.query.sort)
    {
      sort.value = route.query.sort
    }
    const changeSort = () => {
      router.push({ path: '/properties', query: {location: location.value, bathrooms: bathrooms.value, bedrooms: bedrooms.value, project: project.value, type: type.value, readiness: readiness.value, area: area.value, sort: sort.value} })
    }

    watch(() => route.query, newQuery => {
      queries.value = newQuery
      // console.log(queries.value);
      readiness.value = newQuery.readiness ? newQuery.readiness : ''
      type.value = newQuery.type ? newQuery.type : ''
      area.value = newQuery.area ? newQuery.area : ''
      project.value = newQuery.project ? newQuery.project : ''
      bedrooms.value = newQuery.bedrooms ? newQuery.bedrooms : ''
      bathrooms.value = newQuery.bathrooms ? newQuery.bathrooms : ''
      sort.value = newQuery.sort ? newQuery.sort : ''
      properties.value = null
      loadProperties(queries.value)
    })

    const toggleFilter = () => {
      showFilter.value = !showFilter.value
    }

    const clearAll = () => {
      showFilter.value = false
      router.push({ path: '/properties'})
    }

    return {properties, areas, types, projects, areError, propError, typesError, projError, readiness, changeReadiness, type, changeType, area, changeArea, project, changeProject, bedrooms, changeBedrooms, bathrooms, changeBathrooms, sort, changeSort, imgUrl, mainMeta, queries, toggleFilter, showFilter, clearAll}
  }
}
</script>

<style scoped>
.all-props {
  min-height: 30rem;
  margin-top: 10rem;
}
.page-title {
  font-weight: bold;
}
.form-control, .form-select {
  border-color: var(--mainColor);
}
.form-control:active, .form-control:focus, .form-select:active, .form-select:focus {
  box-shadow: none;
  border-color: var(--mainColor);
}
select, select option {
  text-transform: capitalize;
}
.filter {
  display: none;
}
.filter p {
  font-weight: bold;
  cursor: pointer;
}
.filter span {
  background-color: #eee;
  border-radius: 1rem;
  font-size: .7rem;
  text-transform: capitalize;
  margin-right: .5rem;
  margin-bottom: 1rem;
  padding: .2rem .4rem;
}
.filter p.active {
  color: var(--mainColor);
}
.filter p i.arrow {
  font-size: .8rem;
}
.filter p i.arrow svg {
  transition: all ease-in-out .1s;
}
.filter p.active i.arrow svg {
  transform: rotate(90deg);
}
@media (max-width: 767.98px) {
  .filter {
    display: block;
  }

  form {
    display: none;
    margin-top: 1rem;
  }

  form.show {
    display: block;
  }
}

@media (max-width: 575.98px) {
  .all-props {
    margin-top: 6rem;
  }
}
</style>
