<template>
  <div class="box">
    <a class="item item-whatsapp" target="_blank" :href="isMobile() ? `https://api.whatsapp.com/${text}` : `https://web.whatsapp.com/${text}`"><i><FontAwesome :icon="['fab', 'whatsapp']"/></i></a>
    <a class="item item-phone" href="tel:+971502747084"><i><FontAwesome icon="phone" /></i></a>
  </div>
</template>

<script>
import isMobile from '@/services/isMobile'
import { ref } from '@vue/reactivity'
export default {
  setup() {
    const text = ref('send?phone=971502747084&text=Hi')
    return{isMobile,text}
  },
}
</script>

<style scoped>
.box {
  position: fixed;
  right: 0;
  bottom: 3rem;
  display: flex;
  flex-direction: column;
  z-index: 3;
}
.item {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: rgba(255, 255, 255, .85);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, .4);
}
.item-whatsapp {
  color: #2db742;
}
.item-phone {
  color: var(--mainColor);
}
</style>