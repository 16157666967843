<template>
  <div class="col-12">
    <div class="loader">
      <div class="ball"></div>
      <div class="ball"></div>
      <div class="ball"></div>
    </div>
  </div>
</template>

<style scoped>
.loader {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.ball {
  width: 25px;
  height: 25px;
  margin: 1rem;
  border-radius: 50%;
  background-color: #eeeeee;
  animation: bounce .5s alternate infinite;
}
.ball:nth-child(2) {
  animation-delay: .16s;
}
.ball:nth-child(3) {
  animation-delay: .32s;
}
@keyframes bounce {
  from {
    transform: scaleX(1.25);
  }
  to {
    transform: translateY(-50px) scaleX(1);
  }
}
</style>
