<template>
  <div class="container-fluid header">
    <div class="row">
      <div class="col-12">
        <h1 class="title">capex properties</h1>
      </div>
      <div class="col-12">
        <SearchBar />
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from '../SearchBar.vue'
export default {
  components: {
    SearchBar
  }
}
</script>

<style scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url('@/assets/images/capex-header.webp');
  background-color: #cccccc;
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.header::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .2);
}
.row {
  gap: 1rem;
  z-index: 1;
}
.title {
  font-size: 3rem;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;

}
@media (max-width: 767.98px) { 
  .title {
    font-size: 2.5rem;
  }
}
@media (max-width: 575.98px) {
  .title {
    font-size: 2rem;
  }
  /* .header {
  background-position-x: 65%;
  } */
}
</style>