<template>
  <div class="overlay" @click="closeMenu" :class="{show: menuOpen}"></div>
  <nav
    class="navbar"
    :class="{ sticky: scrollPosition > 50, forced: $route.path !== '/' }"
  >
    <div class="logo">
      <router-link to="/"
        ><img
          class="img"
          src="@/assets/images/capex-logo.jpg"
          alt="Capex Properties Real Estate Agency Dubai UAE Logo"
      /></router-link>
    </div>
    <ul class="menu" :class="{ open: menuOpen }">
      <li>
        <router-link @click="closeMenu"  to="/">home</router-link>
      </li>
      <li>
        <router-link @click="closeMenu" :to="{ name: 'allProperties' }">buy</router-link>
      </li>
      <li>
        <a
          class="dropdown-toggle"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          projects
        </a>
        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li>
            <router-link @click="closeMenu" class="dropdown-item" :to="{ name: 'allProjects' }"
              >all projects</router-link
            >
          </li>
          <li>
            <router-link @click="closeMenu" class="dropdown-item" :to="{ name: 'allProjects', query: {type: 'off-plan'} }"
              >off-plan projects</router-link
            >
          </li>
          <li>
            <router-link @click="closeMenu" class="dropdown-item" :to="{ name: 'allProjects', query: {type: 'ready'} }"
              >ready projects</router-link
            >
          </li>
        </ul>
      </li>
      <li>
        <a
          class="dropdown-toggle"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          sell your property
        </a>
        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li>
            <router-link @click="closeMenu" class="dropdown-item" :to="{ name: 'sell' }"
              >list for sale</router-link
            >
          </li>
          <li>
            <router-link @click="closeMenu" class="dropdown-item" :to="{ name: 'rent' }"
              >list for rent</router-link
            >
          </li>
        </ul>
      </li>
      <li><router-link @click="closeMenu" :to="{ name: 'mortgage' }">mortgage</router-link></li>
      <li><router-link @click="closeMenu" :to="{ name: 'about' }">about us</router-link></li>
      <li><router-link @click="closeMenu" :to="{ name: 'contactus' }">contact us</router-link></li>
    </ul>
    <div class="menu-icon">
      <span class="bars" @click="openMenu" v-show="menuClose"><FontAwesome icon="bars" /></span>
      <span class="xmark" @click="closeMenu" v-show="menuOpen"><FontAwesome icon="xmark" /></span>
    </div>
  </nav>
</template>

<script>
import { ref } from "@vue/reactivity"
import { onMounted } from "@vue/runtime-core"
export default {
  setup() {
    let scrollPosition = ref(null);
    let menuOpen = ref(false)
    let menuClose = ref(true)

    let openMenu = () => {
      menuOpen.value = true
      menuClose.value = false
    }

    let closeMenu = () => {
      menuClose.value = true
      menuOpen.value = false
    }

    onMounted(() => {
      window.addEventListener("scroll", () => {
        scrollPosition.value = window.scrollY;
      })
    })

    return { scrollPosition, menuOpen, menuClose, openMenu, closeMenu };
  },
};
</script>

<style scoped>
.overlay {
  display: none;
}
nav {
  z-index: 4;
  position: fixed;
  top: 0;
  width: 100%;
  padding: .5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.2s;
  background-color: #ffffff;
}
.logo {
  width: 7rem;
  transition: 0.3s;
}
.sticky {
  box-shadow: 0px 0px 5px rgba(0,0,0,.5);
}
.forced {
  background-color: #ffffff;
  box-shadow: 0px 0px 5px rgba(0,0,0,.5);
}
ul.menu {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
ul.menu li {
  position: relative;
  text-transform: capitalize;
  font-weight: bold;
  color: var(--mainColor);
}
ul.menu li::after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 1px;
  background-color: var(--mainColor);
  transition: all ease-in-out .3s ;
}
ul.menu li:hover::after {
  width: 100%;
}
.dropdown-menu {
  background-color: #ffffff;
  margin-top: .5rem;
}
.dropdown-item {
  font-weight: bold;
  color: var(--mainColor);
  padding: .5rem 1rem;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: var(--mainColor);
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #e9ecef;
}
.menu-icon {
  display: none;
    color: var(--mainColor);
    font-size: 1.5rem;
    cursor: pointer;
}

@media (max-width: 991.98px) {
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    z-index: 4;
  }
  .overlay.show {
    display: block;
  }
  ul.menu {
    position: absolute;
    top: 4.2rem;
    left: -255px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 3rem;
    height: 100vh;
    background: #ffffff;
    align-items: flex-start;
    padding: 2rem;
    width: 250px;
    overflow-y: scroll;
    transition: .3s;
  }
  ul.menu.open {
    left: 0;
  }
  .menu-icon {
    display: block;
  }
  .dropdown-menu {
    position: static;
    background-color: #ffffff;
    border: none;

  }
}
@media (max-width: 575.98px) {
  nav {
    padding: .5rem 1rem;
  }
}
</style>
