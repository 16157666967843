import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ContactusView from '../views/ContactusView.vue'
import AllProjectsView from '../views/AllProjectsView.vue'
import OneProjectView from '../views/OneProjectView.vue'
import AllPropertiesView from '../views/AllPropertiesView.vue'
import OnePropertyView from '../views/OnePropertyView.vue'
import SellView from '../views/SellView.vue'
import RentView from '../views/RentView.vue'
import MortgageView from '../views/MortgageView'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: process.env.VUE_APP_BASE_TITLE
    }
  },
  {
    path: '/about-us',
    name: 'about',
    component: AboutView,
    meta: {
      title: `About Us | ${process.env.VUE_APP_BASE_TITLE}`
    }
  },
  {
    path: '/contact-us',
    name: 'contactus',
    component: ContactusView,
    meta: {
      title: `Contact Us | ${process.env.VUE_APP_BASE_TITLE}`
    }
  },
  {
    path: '/projects',
    name: 'allProjects',
    component: AllProjectsView,
    meta: {
      title: `Projects | ${process.env.VUE_APP_BASE_TITLE}`
    }
  },
  {
    path: '/projects/:slug',
    name: 'oneProject',
    component: OneProjectView,
    props: true
  },
  {
    path: '/properties',
    name: 'allProperties',
    component: AllPropertiesView,
    meta: {
      title: `Properties | ${process.env.VUE_APP_BASE_TITLE}`
    }
  },
  {
    path: '/properties/:slug',
    name: 'oneProperty',
    component: OnePropertyView,
    props: true
  },
  {
    path: '/sell-your-property',
    name: 'sell',
    component: SellView,
    meta: {
      title: `Sell Your Property | ${process.env.VUE_APP_BASE_TITLE}`
    }
  },
  {
    path: '/rent-your-property',
    name: 'rent',
    component: RentView,
    meta: {
      title: `Rent Your Property | ${process.env.VUE_APP_BASE_TITLE}`
    }
  },
  {
    path: '/mortgage',
    name: 'mortgage',
    component: MortgageView,
    meta: {
      title: `Mortgage | ${process.env.VUE_APP_BASE_TITLE}`
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'contactus' }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  routes
})

router.beforeEach((to, from, next) => {
    if(!to.meta.title) {
      to.meta.title = `${to.params.slug.replaceAll('-', ' ').toUpperCase()} | ${process.env.VUE_APP_BASE_TITLE}`
    }
    if(to.name === 'allProjects' && to.query.type) {
      to.meta.title = `${to.query.type.toUpperCase()} ${to.meta.title}`
    }
    if(to.name === 'allProjects' && to.query.developer) {
      to.meta.title = `${to.query.developer.replaceAll('-', ' ').toUpperCase()} ${to.meta.title}`
    }
    if(to.name === 'allProjects' && to.query.area) {
      to.meta.title = `${to.query.area.replaceAll('-', ' ').toUpperCase()} ${to.meta.title}`
    }
    document.title = to.meta.title
  next()
})

export default router
