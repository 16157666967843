<template>
  <div v-for="project in projects" :key="project.id" class="col-12 col-md-6 col-lg-4 mt-4">
    <router-link :to="{ name: 'oneProject', params: { slug: project.slug } }">
      <div class="box">
        <!-- <div class="price" v-if="project.start_price">
          <span>AED {{project.start_price.toLocaleString('en-US')}}</span>
        </div> -->
        <div class="image">
        <img
        class="img"
          :src="`${imgUrl}${project.main_image}`"
          alt="CAPEX PROPERTIES Real Estate Agency"
        />
        </div>
        <div class="content">
          <h1 class="name">{{project.name}}</h1>
          <h2 class="location">{{project.area_name}}</h2>
        </div>
      </div>
    </router-link>
  </div>
  <div v-if="projects.length == 0">
    <h2 class="mt-5 no-data">No Projects</h2>
  </div>
</template>

<script>
export default {
  props: ['projects', 'imgUrl'],
};
</script>

<style scoped>
.box {
  position: relative;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}
.price {
  position: absolute;
  inset: 0;
}
.price span {
  padding: 4px 10px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  font-size: 1.5rem;
  
}
.image {
  height: 250px;
}
.content {
  margin-top: 10px;
  text-align: center;
  padding: 1rem;
}
.name {
  text-transform: capitalize;
  font-size: 1.5rem;
}
.readiness {
  text-transform: uppercase;
  font-size: .8rem;
}
.location {
  text-transform: capitalize;
  font-size: 1rem;
}
.no-data {
  text-align: center;
}

@media (max-width: 575.98px) {
  .image {
    height: 200px;
  }
}
</style>
