<template>
  <div class="container one-property">
    <div class="row" v-if="property">
      <div class="col-md-8">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">{{ property.area.name }}</li>
            <li v-if="property.project_name != 'unknown'" class="breadcrumb-item">{{ property.project_name }}</li>
          </ol>
        </nav>
        <h1 class="title">{{ property.title }}</h1>
        <div class="location">
          <span class="location-dot"
            ><FontAwesome icon="location-dot" /> {{ property.area.name }}</span
          >
        </div>
        <div class="price-features">
          <h2 class="price">AED {{ property.price.toLocaleString('en-US') }}</h2>
          <div class="features">
            <span class="bed"
              ><FontAwesome icon="bed" /> {{ property.bedrooms }}</span
            >
            |
            <span class="bath"
              ><FontAwesome icon="bath" /> {{ property.bathrooms }}</span
            >
            |
            <span class="car"
              ><FontAwesome icon="car" /> {{ property.parking }}</span
            >
            |
            <span class="arrow-up"
              ><FontAwesome icon="arrow-up-right-from-square" />
              {{ property.size.toLocaleString('en-US') }} Sq.Ft</span
            >
          </div>
        </div>
        <div class="description">
          <h2>description</h2>
          <!-- <div class="box" v-html="property.description"></div> -->
          <p class="box">{{property.description}}</p>
          
        </div>
      </div>
      <div class="col-md-4">
        <div class="map">
          <iframe
            :src="property.area.link"
            width="100%"
            height="300"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div class="col-12" v-if="property.images.length>0" >
        <TheGallery :imgList="property.images" :baseUrl="baseUrl" />
      </div>
    </div>
    <div class="row" v-else-if="error">
      <div class="col-12">
        <h2 class="error">An Error Happened!</h2>
      </div>
    </div>
    <div class="row" v-else>
      <TheLoading />
    </div>
  </div>
</template>

<script>
import TheLoading from "../components/TheLoading.vue"
import TheGallery from "../components/TheGallery.vue"
import getProperty from '@/services/properties/getProperty'
export default {
  components: {
    TheLoading,
    TheGallery,
  },
  props: ['slug'],
  setup(props) {
    const baseUrl = process.env.VUE_APP_BASE_URL

    const { mainData:property, mainError:error, loadData:loadProperty } = getProperty()
    loadProperty(props.slug)

    return {
      property,
      error,
      baseUrl,
     }
  },
};
</script>

<style scoped>
.one-property {
  min-height: 30rem;
  margin-top: 10rem;
}
.breadcrumb {
  margin-bottom: 1px;
}
.breadcrumb-item {
  text-transform: uppercase;
}
.title {
  font-size: 2rem;
  font-weight: bold;
  text-transform: capitalize;
}
.location {
  text-transform: capitalize;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.price-features {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price {
  font-size: 1.7rem;
  color: var(--mainColor);
  margin: 0;
}
.description {
  border-block: 1px solid #eeeeee;
  margin: 10px 0;
  padding: 1rem 0;
}
.description h2 {
  text-transform: capitalize;
}
.description p {
  white-space: pre-line;
}
@media (max-width: 575.98px) {
  .price-features {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
