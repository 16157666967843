<template>
  <div class="col-12 col-md-6 col-lg-4 mt-4">
    <div class="box">
        <div class="image">
        </div>
        <div class="content">
          <p></p>
          <p></p>
        </div>
      </div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mt-4">
    <div class="box">
        <div class="image">
        </div>
        <div class="content">
          <p></p>
          <p></p>
        </div>
      </div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mt-4">
    <div class="box">
        <div class="image">
        </div>
        <div class="content">
          <p></p>
          <p></p>
        </div>
      </div>
  </div>
</template>

<style scoped>
.box {
  position: relative;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}
.image {
  height: 350px;
  animation: pulse-bg 1s infinite;
}
.content {
  margin-top: 10px;
  text-align: center;
  padding: 1rem;
}
p {
  width: 100%;
  height: 20px;
  animation: pulse-bg 1s infinite;
}
</style>