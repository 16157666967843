<template>
  <div class="form-box">
    <form @submit.prevent="submitForm">
      <div class="form-floating mb-3">
        <input type="text" class="form-control" id="nameInput" v-model="messageData.fullName" placeholder="Full Name" required />
        <label for="nameInput">Full Name *</label>
      </div>
      <div class="form-floating mb-3">
        <input type="email" class="form-control" id="emailInput" v-model="messageData.email" placeholder="Email" required />
        <label for="emailInput">Email Address *</label>
      </div>
      <div class="form-floating mb-3">
        <vue-tel-input v-model="messageData.phone" :value="messageData.phone" v-bind="bindProps" @input="onInput"></vue-tel-input>
        <!-- <input type="tel" class="form-control" id="phoneInput" v-model="messageData.phone" placeholder="Phone" required />
        <label for="phoneInput">Phone Number *</label> -->
      </div>
      <div class="form-floating mb-3">
        <input type="number" min="0" class="form-control" id="propValInput" v-model="messageData.propVal" placeholder="Property Value AED" required />
        <label for="propValInput">Property Value AED *</label>
      </div>
      <div class="form-floating mb-3">
        <input type="number" min="0" class="form-control" id="incomeInput" v-model="messageData.income" placeholder="Annual Income AED" required />
        <label for="incomeInput">Annual Income AED *</label>
      </div>
      <select class="form-select mb-3" id="propTypeInput" v-model="messageData.propType">
        <option selected value="Resident">Resident</option>
        <option value="Non Resident">Non Resident</option>
      </select>
      <div class="text-center button">
        <button class="btn btn-danger" type="submit">Get Your Free Consultation</button>
      </div>
      <p class="text-center mt-3 message-status">{{ messageStatus }}</p>
    </form>
  </div>
</template>

<script>

const queryString = require('query-string')
import { ref } from '@vue/reactivity'
import axios from 'axios'
import { VueTelInput } from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';
export default {
props: ['subject'],
components: {
    VueTelInput,
  },
setup(props) {
  const messageStatus = ref('')
  const url = process.env.VUE_APP_MESSAGE_URL

  const messageData = ref({
    'fullName': '',
    'email': '',
    'phone': '',
    'code': '',
    'propVal': '',
    'income': '',
    'propType': 'Resident',
    'subject' : props.subject
  })

const bindProps = {
  mode: 'international',
  preferredCountries: ['AE'],
  inputOptions: {
    required: true,
    placeholder: 'Enter a Phone Number',
    type: 'tel',
    autocomplete: 'on',
    maxlength: 25,
    name: 'phone',
    tabindex: 0,
    id: 'phoneInput',
  }
}

const onInput = (phone, phoneObject) => {
  if (phoneObject?.formatted) {
    messageData.value.phone = phoneObject.formatted
    messageData.value.code = phoneObject.countryCode
  }
}

  const submitForm = async () => {
    // console.log('submitted: ', queryString.stringify(messageData.value));
    messageStatus.value = 'Sending...'
    // console.log(messageData.value)
    try {
        let response = await axios.post(
        `${url}/mortgagemessage.php`,
        queryString.stringify(messageData.value),
         { 
          'Accept': 'application/json', 
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      )
        if (response) {
          messageStatus.value = response.data;
          if (response.data === "Message was sent successfully") {
            setTimeout(() => {
              messageStatus.value = "";
              messageData.value.fullName = "";
              messageData.value.phone = "";
              messageData.value.code = "";
              messageData.value.email = "";
              messageData.value.propVal = "";
              messageData.value.income = "";
              messageData.value.propType = "Resident";
            }, 3500);
          }
        } else {
          messageStatus.value = "Something went wrong!";
        }
    } catch (err) {
        messageStatus.value = err.message
        // console.log(err)
    }
  }
  return {bindProps, messageData, onInput, submitForm, messageStatus}
}

}
</script>

<style scoped>
.form-box {
  padding: 2.5rem;
}
.form-control, .form-select {
border-color: var(--mainColor);
}
.form-control:active, .form-control:focus, .form-select:active, .form-select:focus {
box-shadow: none;
border-color: var(--mainColor);
}
.message-status {
font-weight: bold;
color: var(--mainColor);
}
</style>