<template>
  <TheNav />
  <router-view/>
  <TheFooter />
  <FixedContact />
</template>

<script>
import TheNav from './components/TheNav.vue'
import TheFooter from './components/TheFooter.vue'
import FixedContact from './components/FixedContact.vue'
export default {
  components: { TheNav, TheFooter, FixedContact }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&display=swap');

html {
  overflow-x: hidden;
}
body {
  position: relative;
  font-family: 'Raleway', sans-serif;
  margin: 0 ;
  padding: 0 ;
  overflow-x: hidden;
  --mainColor: #cd1c37;
  font-variant-numeric: lining-nums;
}
ul {
  list-style: none ;
}
a {
  text-decoration: none ;
  color: unset ;
}
a:hover {
  color: unset;
}
img.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.color-main {
  color: var(--mainColor);
}
@keyframes pulse-bg {
  0% {
    background-color: #eeeeee;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #eeeeee;
  }
}
</style>
