import { ref } from '@vue/reactivity'
import instance from '@/http-common'

const getData = () => {
  const mainData = ref(null)
  const mainError = ref(null)
  const loadData = async () => {
      try {
          let response = await instance.get('/types')
          // if (response.status != 200) {
          //     throw Error('Something Went Wrong!')
          // }
          // console.log(response.data.data)
          mainData.value = response.data.data
      } catch (err) {
          mainError.value = err.message
        //   console.log(err)
        //   console.log(mainError.value)
      }
  }

  return {mainData, mainError, loadData}
}

export default getData
