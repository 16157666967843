import "bootstrap/dist/css/bootstrap.min.css";


import vue3PhotoPreview from "vue3-photo-preview";
import "vue3-photo-preview/dist/index.css";

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import {createHead} from '@vueuse/head'

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
// import { far } from "@fortawesome/free-regular-svg-icons"
import {
  faTasks,
  faChartLine,
  faHandshake,
  faCheckCircle,
  faEnvelope,
  faPhone,
  faMobileAlt,
  faAngleRight,
  faMoneyBillWave,
  faDownload,
  faXmark,
  faXmarkCircle,
  faBars,
  faBed,
  faBath,
  faCar,
  faArrowUpRightFromSquare,
  faLocationDot,
  faCaretDown,
  faFilter,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import {
  faWhatsapp,
  faFacebookF,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
library.add(
  faTasks,
  faChartLine,
  faHandshake,
  faCheckCircle,
  faEnvelope,
  faPhone,
  faMobileAlt,
  faAngleRight,
  faMoneyBillWave,
  faDownload,
  faXmark,
  faXmarkCircle,
  faCaretDown,
  faLocationDot,
  faArrowUpRightFromSquare,
  faBars,
  faBath,
  faBed,
  faCar,
  faWhatsapp,
  faFacebookF,
  faInstagram,
  faFilter,
  faChevronRight
);

createApp(App)
  .use(store)
  .component("FontAwesome", FontAwesomeIcon)
  .use(router)
  .use(createHead())
  .use(vue3PhotoPreview)
  .component('v-select', vSelect)
  .mount("#app");

import "bootstrap/dist/js/bootstrap.js";
