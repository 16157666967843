<template>
  <div class="container mt-5 contactus">
    <div class="row">
      <div class="col-12">
        <h2>WHO ARE WE</h2>
      </div>
      <div class="col-xl-5 col-lg-6">
        <div class="image">
          <img src="@/assets/images/about3.jpg" alt="capex properties real estate agency dubai">
        </div>
      </div>
      <div class="col-xl-7 col-lg-6">
        <div class="mb-5 about">
          <h1>Capex Properties</h1><p> includes financial and real estate experts who specialize in finding the best properties and financial options for clients in Dubai and UAE.</p>
          <router-link :to="{name: 'about'}" class="mt-3">Explore More</router-link>
        </div>
        <h2>CONTACT US</h2>
        <h4>Share Your Details and Stay Updated on All Offers and Deals!</h4>
        <div class="mt-4 contact-form">
          <ContactusForm subject="Email from Capex Website" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactusForm from '../ContactusForm.vue'
export default {
  components: {
    ContactusForm
  }
}
</script>

<style scoped>
h1 {
  display: inline;
    color: var(--mainColor);
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 5px;
    margin-bottom: 0;
}
p {
  display: inline;
  font-size: 1.2rem;
    line-height: 2;
    margin-bottom: 0;
}
a {
  display: table;
  border-left: 3px solid var(--mainColor);
  border-bottom: 3px solid var(--mainColor);
  padding-left: 5px;
  padding-bottom: 5px;

}
h2, h4 {
  font-weight: bold;
}
h2 {
    text-align: center;
    margin-bottom: 2rem;
}
.image {
  height: 91%;
}
img {
  width: 100%;
  height: 100%;
}
@media (max-width: 991.98px) { 
  h2 {
    text-align: center;
    border-top: 1px solid var(--mainColor);
    padding-top: 1rem;
  }
  .image {
    height: 28rem;
  }
  .about {
    margin-top: 1rem;
  }
 }
</style>