<template>
        <Carousel :autoplay="autoplay"  :wrap-around="true" :settings="settings"  :breakpoints="breakpoints">
          <Slide v-for="image in images" :key="image">
            <div class="carousel__item"><img :src="require(`@/assets/images/${image}`)" :alt="alt"></div>
          </Slide>
        </Carousel>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
export default {
  props: ['images', 'alt', 'autoplay'],
  components: {
    Carousel,
    Slide
  },
  data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 2,
      snapAlign: 'start',
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      767.98: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
      991.98: {
        itemsToShow: 4,
        snapAlign: 'start',
      },
      // 1199.98 and up
      1199.98: {
        itemsToShow: 5,
        snapAlign: 'start',
      },
    },
  }),
}
</script>

<style scoped>

img {
  width: 11.5rem;
}
@media (max-width: 575.98px) { 
  img {
    width: 8.5rem;
  }
}
</style>