<template>
  <nav aria-label="Page navigation">
    <ul class="mt-5 pagination justify-content-center">
      <li v-for="(link, index) in mainMeta.links" :key="index" class="page-item" :class="{disabled: !link.url || link.active, active: link.active}">
        <router-link v-if="link.label.includes('Previous')" class="page-link" :to="{ name: 'allProjects', query: { page: mainMeta.current_page - 1, search: queries.search, developer: queries.developer, type: queries.type, area: queries.area } }">&laquo;</router-link>
        <router-link v-else-if="link.label.includes('Next')" class="page-link" :to="{ name: 'allProjects', query: { page: mainMeta.current_page + 1, search: queries.search, developer: queries.developer, type: queries.type, area: queries.area } }">&raquo;</router-link>
        <router-link v-else class="page-link" :to="{ name: 'allProjects', query: { page: link.label, search: queries.search, developer: queries.developer, type: queries.type, area: queries.area } }">{{ link.label }}</router-link>
      </li>
      <!-- <li class="page-item disabled">
        <a class="page-link" >
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li class="page-item active"><a class="page-link" href="#">1</a></li>
      <li class="page-item"><a class="page-link" href="#">2</a></li>
      <li class="page-item"><a class="page-link" href="#">3</a></li>
      <li class="page-item">
        <a class="page-link" href="#">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li> -->
    </ul>
  </nav>
</template>

<script>
export default {
  props: ['mainMeta', 'queries'],
  // setup(props) {
  //   console.log(props.mainMeta.links);
  //   console.log(props.queries);
  // }
}
</script>

<style scoped>
.page-link {
  color: var(--mainColor);
}
.page-item.active .page-link {
  background-color: var(--mainColor);
  border-color: var(--mainColor);
  z-index: unset;
}
.page-link:focus, .page-link:active {
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
}
.page-item.disabled .page-link {
  color: #000000;
}
@media (max-width: 575.98px) {
  .pagination {
    flex-wrap: wrap;
  }
  .page-link {
    padding: .1rem .6rem;
  }
}
</style>
