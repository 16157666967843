<template>
  <div class="container mt-5 featured-proj">
    <div class="row">
      <h1>FEATURED PROJECTS</h1>
    </div>
    <div class="row justify-content-center" v-if="projects">
      <ProjectBox :projects="projects" :imgUrl="imgUrl" />
      <div class="mt-5 view-all">
        <router-link :to="{ name: 'allProjects' }">view all</router-link>
      </div>
    </div>
    <div class="row justify-content-center" v-else-if="error">
      <div class="col-12">
        <h2 class="error">An Error Happened!</h2>
      </div>
    </div>
    <div class="row justify-content-center" v-else>
      <BoxLoading />
    </div>
  </div>
</template>

<script>
import getThreeProjects from '@/services/projects/getThreeProjects'
import ProjectBox from "../ProjectBox.vue"
import BoxLoading from "@/components/BoxLoading.vue"
export default {
  components: {
    ProjectBox,
    BoxLoading
  },
  setup() {
    const { mainData:projects, mainError:error, loadData } = getThreeProjects()
    loadData()
    const imgUrl = process.env.VUE_APP_BASE_URL

    return {projects, error, imgUrl}
  }
}
</script>

<style scoped>
h1 {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
}
.error {
  color: crimson;
}
.view-all {
  text-align: center;
}
.view-all a {
  text-transform: uppercase;
  background-color: var(--mainColor);
  padding: .3rem .5rem;
  color: #ffffff;
}

@media (max-width: 575.98px) {
  h1 {
    font-size: 1.3rem;
  }
}
</style>