<template>
  <TheModal>
    <MortgageForm subject="Message from Website, Mortgage"/>
  </TheModal>
</template>

<script>
import MortgageForm from "@/components/MortgageForm.vue"
import TheModal from "@/components/TheModal.vue"
export default {
  components: {
    MortgageForm,
    TheModal
  },
}
</script>

<style scoped>
.modal-animation-enter-active,
.modal-animation-leave-active {
  transition: opacity 0.2s cubic-bezier(.52, .02, .19, 1.02);
}
.modal-animation-enter-from,
.modal-animation-leave-to {
  opacity: 0;
}

.modal-animation-inner-enter-active {
  transition: all 0.2s cubic-bezier(.52, .02, .19, 1.02) .1s;
}
.modal-animation-inner-leave-active {
  transition: all 0.2s cubic-bezier(.52, .02, .19, 1.02);
}
.modal-animation-inner-enter-from {
  opacity: 0;
  transform: scale(0.8);
}
.modal-animation-inner-leave-to {
  transform: scale(0.8);
}

.download-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-inner {
  background-color: #000000;
  max-width: 550px;
  width: 90%;
}
.xmark {
  position: absolute;
  margin-left: 1rem;
  margin-top: 1rem;
  color: #ffffff;
  font-size: 1rem;
}
</style>