<template>
    <form @submit.prevent="submitForm">
      <div class="form-floating mb-3">
        <input type="text" class="form-control" id="nameInput" v-model="messageData.fullName" placeholder="Full Name" required />
        <label for="nameInput">Full Name *</label>
      </div>
      <div class="form-floating mb-3">
        <input type="email" class="form-control" id="emailInput" v-model="messageData.email" placeholder="Email" required />
        <label for="emailInput">Email Address *</label>
      </div>
      <div class="form-floating mb-3">
        <vue-tel-input v-model="messageData.phone" :value="messageData.phone" v-bind="bindProps" @input="onInput"></vue-tel-input>
        <!-- <input type="tel" class="form-control" id="phoneInput" v-model="messageData.phone" placeholder="Phone" required />
        <label for="phoneInput">Phone Number *</label> -->
      </div>
      <div class="form-floating mb-3">
        <textarea class="form-control" id="messageInput" v-model="messageData.message" placeholder="Leave your message" required></textarea>
        <label for="messageInput">Details *</label>
      </div>
      <div class="text-center button">
        <button class="btn btn-danger" type="submit">Send</button>
      </div>
      <p class="text-center mt-3 message-status">{{ messageStatus }}</p>
    </form>
</template>

<script>
const queryString = require('query-string')
import { ref } from '@vue/reactivity'
import { VueTelInput } from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';
import axios from 'axios'
export default {
  props: ['subject'],
  components: {
    VueTelInput,
  },
  setup(props) {
    const messageStatus = ref('')
    const url = process.env.VUE_APP_MESSAGE_URL

    const messageData = ref({
      'fullName': '',
      'email': '',
      'phone': '',
      'code': '',
      'message': '',
      'subject' : props.subject
    })

    const bindProps = {
      mode: 'international',
      preferredCountries: ['AE'],
      inputOptions: {
        required: true,
        placeholder: 'Enter a Phone Number',
        type: 'tel',
        autocomplete: 'on',
        maxlength: 25,
        name: 'phone',
        tabindex: 0,
        id: 'phoneInput',
      }
    }

    const onInput = (phone, phoneObject) => {
      if (phoneObject?.formatted) {
        messageData.value.phone = phoneObject.formatted
        messageData.value.code = phoneObject.countryCode
      }
    }

    // const submitForm = () => {
    //   console.log('submitted: ', messageData.value);
    // }

    const submitForm = async () => {
      // console.log('submitted: ', queryString.stringify(messageData.value));
      messageStatus.value = 'Sending...'
      // console.log(messageData.value)
      try {
          let response = await axios.post(
          `${url}/message.php`,
          queryString.stringify(messageData.value),
           { 
            'Accept': 'application/json', 
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        )
          if (response) {
            messageStatus.value = response.data;
            if (response.data === "Message was sent successfully") {
              setTimeout(() => {
                messageStatus.value = "";
                messageData.value.fullName = "";
                messageData.value.phone = "";
                messageData.value.code = "";
                messageData.value.email = "";
                messageData.value.message = "";
              }, 3500);
            }
          } else {
            messageStatus.value = "Something went wrong!";
          }
      } catch (err) {
          messageStatus.value = err.message
          // console.log(err)
      }
    }
    return {bindProps, messageData, onInput, submitForm, messageStatus}
  }

}
</script>

<style scoped>
textarea {
  min-height: 8rem !important;
}
.form-control {
  border-color: var(--mainColor);
}
.form-control:active, .form-control:focus {
  box-shadow: none;
  border-color: var(--mainColor);
}
.message-status {
  font-weight: bold;
  color: var(--mainColor);
  background-color: #ffffff;
}
</style>