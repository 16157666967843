<template>
  <div class="container about">
    <div class="mb-3 row">
      <div class="col-12">
        <h1 class="text-center text-md-start"><span>About </span>CAPEX Properties</h1>
      </div>
    </div>
      <div class="row align-items-center">
        <div class="col-md-6">
          <p>Capex Properties includes financial and real estate experts who specialize in finding the best properties and financial options for clients in Dubai and UAE.</p>
          <p>We aim to assist our clients achieving their full financial prospects by educating and motivating them to implement sophisticated, safe and effective financial structures in order to achieve their lifestyle goals.</p>
        </div>
        <div class="col-md-6">
          <div class="image"><img src="@/assets/images/about3.jpg" alt="" class="img"></div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-md-6 order-last order-md-first">
          <div class="image"><img src="@/assets/images/about2.jpg" alt="" class="img"></div>
        </div>
        <div class="col-md-6">
          <p>We pride ourselves by supporting our long-term clients achieving the dream of owning and increasing their wealth through multiple choices of investment in properties and creating their passive income.</p>
        </div>
      </div>
        <div class="row align-items-center">
        <div class="col-md-6">
          <p>Whether you are a home buyer, property investor or seeking financial assistance, it’s always our pleasure to be your financial partner and property advisor.</p>
          <p><span class="contact"><router-link :to="{name: 'contactus'}">Contact us </router-link></span> to find out more about how we can support your choices today!</p>
        </div>
        <div class="col-md-6">
          <div class="image"><img src="@/assets/images/about.jpg" alt="" class="img"></div>
        </div>
      </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'
export default {
  setup() {
    useHead({
      meta: [
        {
          name: `description`,
          content: `Capex Properties includes financial and real estate experts who specialize in finding the best properties and financial options for clients in Dubai and UAE.`
        },
        {
          property: `og:title`,
          content: `About Us | ${process.env.VUE_APP_BASE_TITLE}`
        },
        {
          property: `og:description`,
          content: `Capex Properties includes financial and real estate experts who specialize in finding the best properties and financial options for clients in Dubai and UAE.`
        },
        {
          property: `og:url`,
          content: `https://capexproperties.ae/about-us`
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:site_name`,
          content: process.env.VUE_APP_BASE_TITLE
        },
      ],
      link: [
        {
          rel: `canonical`,
          href: `https://capexproperties.ae/about-us`
        }
      ]
    })
  },
}
</script>

<style scoped>

.about {
  margin-top: 6rem;
}
h1 {
  font-weight: bold;
  color: var(--mainColor);
}
h1 span {
  color: #000000;
}
p {
  font-size: 1.2rem;
}
.image {
  margin-bottom: 10px;
  height: 450px;
}
img {
  object-fit: contain;
}
.contact {
  color: var(--mainColor);
  font-weight: bold;
}
@media (min-width: 768px) { 
  .image {
    height: unset;
  }
  img {
    object-fit: cover;
  }
}
@media (min-width: 576px) { 
.about {
  margin-top: 10rem;
}
  p {
    line-height: 2;
  }
}
</style>