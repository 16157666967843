<template>
  <div v-if="project" class="my-container">
    <DownloadModal @close="closeModal" :projectName="project.name" :modalActive="modalActive" :modalTitle="modalTitle" :downloadPdf="downloadPdf" />
    <div class="container-fluid header" :style="{ backgroundImage: `url(${baseUrl}${project.main_image})` }">
      <div class="row align-items-center content">
        <div class="col-lg-8 col-md-6">
          <div class="content-box">
            <div class="developer">
              <img :src="`${baseUrl}${project.developer.logo}`" :alt="project.developer.name">
            </div>
            <div class="project">
              <img v-if="project.logo" :src="`${baseUrl}${project.logo}`" :alt="project.name">
              <h2 class="mt-3 name">{{ project.name }}<span class="me-1 location-dot">, <FontAwesome icon="location-dot" /></span><span class="location">{{ project.area.name }}</span>
              </h2>
              <h5 v-if="project.header_description" class="header_description">{{ project.header_description }}</h5>
              <h3 v-if="project.start_price != 0" class="start_price">starting from AED {{ project.start_price.toLocaleString('en-US') }}</h3>
              <div class="mt-3 social">
                <h4>Chat with us</h4>
                <a target="_blank" :href="isMobile() ? `https://api.whatsapp.com/send?phone=971502747084&text=Hi I am interested in ${project.name} project.` : `https://web.whatsapp.com/send?phone=971502747084&text=Hi I am interested in ${project.name} project.`"><i><FontAwesome :icon="['fab', 'whatsapp']"/></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <ProjectForm title="Register Now" buttonTitle="Register Your Interest" :projectName="project.name" :isDownload="false" />
        </div>
      </div>
    </div>
    <div class="container main">
      <div class="row">
        <div class="col-lg-4 col-md-6 mt-4">
          <div class="pricing">
            <h2>Pricing Structure</h2>
            <div v-if="project.structures.data.length==0"><p>To Be Announced</p></div>
            <div v-else v-for="structure in project.structures.data" :key="structure.id">
              <h3>{{ structure.type }}</h3>
              <h4><i><FontAwesome icon="money-bill-wave" /></i> From AED {{ structure.price }}</h4>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mt-4">
          <div class="payment">
            <h2>PAYMENT PLAN</h2>
            <p v-if="project.payment">{{ project.payment }}</p>
            <p v-else>To Be Announced</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 mt-4">
          <div class="configuration">
            <h2>PROJECT CONFIGURATION</h2>
            <div v-if="project.structures.data.length==0"><p>To Be Announced</p></div>
            <div v-else v-for="structure in project.structures.data" :key="structure.id">
              <h3>{{ structure.type }}<span>: {{ structure.size }} Sq-Ft</span></h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12">
          <div class="downloads">
            <button v-if="project.brochure" @click="openModal(project.brochure, $event)"><span class="download"><FontAwesome icon="download" /></span>
              Download Brochure</button>
            <button v-if="project.floor_plan" @click="openModal(project.floor_plan, $event)"><span class="download"><FontAwesome icon="download" /></span>
              Download Floorplan</button>
          </div>
        </div>
      </div>
      <div v-if="project.features.data.length>0" class="row mt-5">
        <div class="col-12">
          <div class="features">
            <h2>FEATURES AND AMENITIES</h2>
            <div class="content">
              <div v-for="feature in project.features.data" :key="feature.id" class="box">
                <img :src="`${baseUrl}${feature.logo}`" alt="capex properties real estate agency dubai uae feature logo">
                <p>{{feature.name}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-6 col-12">
          <div class="mb-3 description">
            <h1>{{project.name}} <span>by</span> {{project.developer.name}} <span>located at</span> {{project.area.name}}</h1>
            <!-- <div v-html="project.description"></div> -->
            <p v-if="project.description">{{project.description}}</p>
          </div>
        </div>
        <div class="col-lg-6 col-12 mb-3">
          <iframe
          :src="project.address_link ? project.address_link : project.area.link"
          width="100%"
          height="400"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        </div>
        <div v-if="project.images.data.length>0" class="col-12">
          <TheGallery :imgList="project.images.data" :baseUrl="baseUrl" />
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="error" class="container-fluid error-container">
    <div class="row">
      <div class="col-12">
        <h2 class="error">An Error Happened!</h2>
      </div>
    </div>
  </div>
  <div v-else class="container-fluid loading-container">
    <div class="row">
      <TheLoading />
    </div>
  </div>
</template>

<script>
import TheLoading from "../components/TheLoading.vue"
import DownloadModal from "../components/DownloadModal.vue"
import ProjectForm from "../components/ProjectForm.vue"
import TheGallery from "../components/TheGallery.vue"
import getProject from '@/services/projects/getProject'
import { ref } from '@vue/reactivity';
import isMobile from '@/services/isMobile'
import { useHead } from '@vueuse/head'

export default {
  components: {
    TheLoading,
    TheGallery,
    DownloadModal,
    ProjectForm
  },
  props: ['slug'],
  setup(props) {

    useHead({
      meta: [
        {
          name: `description`,
          content: `${props.slug.replaceAll('-', ' ')} Project. Get more details: structure, brochure, floorplans, prices, payment plan, location, amenities, developer, image gallery and more about this development at Capex Properties - Dubai.`
        },
        {
          property: `og:title`,
          content: `${props.slug.replaceAll('-', ' ').toUpperCase()} | ${process.env.VUE_APP_BASE_TITLE}`
        },
        {
          property: `og:description`,
          content: `${props.slug.replaceAll('-', ' ')} Project. Get more details: structure, brochure, floorplans, prices, payment plan, location, amenities, developer, image gallery and more about this development at Capex Properties - Dubai.`
        },
        {
          property: `og:url`,
          content: `https://capexproperties.ae/projects/${props.slug}`
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:site_name`,
          content: process.env.VUE_APP_BASE_TITLE
        },
      ],
      link: [
        {
          rel: `canonical`,
          href: `https://capexproperties.ae/projects/${props.slug}`
        }
      ]
    })
    
    const baseUrl = process.env.VUE_APP_BASE_URL
    
    const modalActive = ref(false)
    const downloadPdf = ref('')
    const modalTitle = ref('')

    const openModal = (pdf, event) => {
      modalTitle.value = event.target.innerText
      modalActive.value = true
      downloadPdf.value = pdf
    }
    const closeModal = () => {
      modalActive.value = false
      downloadPdf.value = ''
    }

    const { mainData:project, mainError:error, loadData:loadProject } = getProject()
    loadProject(props.slug)

    

    return {
      modalActive,
      modalTitle,
      downloadPdf,
      openModal,
      closeModal,
      project,
      error,
      baseUrl,
      isMobile
     }
  },
};
</script>

<style scoped>
.loading-container,
.error-container {
  min-height: 30rem;
  margin-top: 10rem;
}
.my-container {
  margin-top: 4rem;
}
.header {
  position: relative;
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.header .content {
  padding: 5rem 3rem;
  height: 100%;
  min-height: 550px;
  background-color: rgba(0, 0, 0, 0.6);
}
.header .content-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}
.header .content-box .developer img {
  width: 150px;
}
.header .content-box .project img {
  width: 150px;
}
.header .content-box .project .name {
  color: #ffffff;
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
}
.header .content-box .project .location {
  color: #ffffff;
  text-transform: capitalize;
}
.header .content-box .project .header_description {
  color: #ffffff;
  margin-top: 1rem;
  text-transform: capitalize;
}
.header .content-box .project .start_price {
  color: #ffffff;
  margin-top: 1rem;
  font-weight: bold;
  text-transform: capitalize;
}
.header .social h4 {
  font-size: 1rem;
  color: #ffffff;
  margin-bottom: 0;
}
.header .social i {
  font-size: 3rem;
  color: #ffffff;
}

.main .pricing, .main .payment, .main .configuration {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main .pricing h2, .main .payment h2, .main .configuration h2 {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--mainColor);
}
.main .pricing h3, .main .pricing h4, .main .configuration h3 {
  text-align: center;
  text-transform: capitalize;
}
.main .pricing h3 {
  font-weight: bold;
  font-size: 1.5rem;
}
.main .pricing h4 {
  font-size: 1.2rem;
  white-space: pre-line;
}
.main .pricing div, .main .configuration div {
  padding: .5rem;
  border-top: 1px solid var(--mainColor);
}
.main .payment p {
  font-size: 1.2rem;
  white-space: pre-line;
  line-height: 2;
  text-align: center;
  text-transform: capitalize;
  padding: .5rem;
  border-top: 1px solid var(--mainColor);
}
.main .configuration h3 {
  font-weight: bold;
  font-size: 1rem;
}
.main .configuration h3 span {
  font-weight: lighter;
  white-space: pre-line;
}

.main .downloads {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.main .downloads button {
  background-color: var(--mainColor);
  color: #ffffff;
  padding: 5px 10px;
  border: none;
  border-radius: 1rem;
}
.main .features {
  margin-top: 3rem;
}
.main .features h2 {
  text-align: center;
  color: var(--mainColor);
  font-weight: bold;
  text-transform: uppercase;
}
.main .features .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 3rem;
  gap: 1rem;
}
.main .features .content .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.main .features .content img {
  width: 30px;
}
.main .features .content p {
  text-align: center;
  color: var(--mainColor);
  font-weight: bold;
  text-transform: capitalize;
  margin-top: 10px;
}
.main .description {
  padding: 1rem 0;
  border-top: 1px solid var(--mainColor);
}
.main .description {
  white-space: pre-line;
}
.main .description h1 {
  font-weight: bold;
  text-transform: capitalize;
  color: var(--mainColor);
  font-size: 2rem;
}
.main .description h1 span {
  text-transform: lowercase;
  font-size: 1.5rem;
  color: #333;
}

@media (max-width: 991.98px) {
  .header .content-box {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
  }
}

@media (max-width: 575.98px) {
  .header .content-box .project .name {
    font-size: 1.5rem;
    line-height: 1.5;
  }
  .header .social i {
    font-size: 2.5rem;
  }
  .main .downloads {
    flex-direction: column;
  }
  .main .features .content {
    flex-direction: column;
  }
  .main .description h1 {
    font-size: 1.7rem;
  }
  .main .description h1 span {
    font-size: 1.2rem;
  }
  iframe {
    height: 250px;
  }
  
.header .content-box .developer img {
  width: 100px;
}
.header .content-box .project img {
  width: 100px;
}
}
</style>
