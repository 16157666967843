<template>
  <MortgageModal @close="closeModal" :modalActive="modalActive"/>
  <div class="mortgage-header">
    <div class="content">
      <h1 class="title">Mortgage and Home Loans Services</h1>
      <!-- <p>Working with a range of UAE banks, Capex Properties guarantee their clients the most suitable mortgage for their individual circumstances. Our experts can help you find the best mortgage offers in Dubai and UAE.</p> -->
      <p>Capex Properties is one of the leading Mortgage Loan Consultancy in Dubai, providing a hassle-free, seamless, and transparent process</p>
      <button @click="openModal()">FREE CONSULTATION</button>
    </div>
    <div class="under-mortgage-header">
      <p><i class="true"><FontAwesome icon="check-circle"/></i>For residents and Non-residents</p>
      <p><i class="true"><FontAwesome icon="check-circle"/></i>Partnered with all banks in the UAE</p>
      <p><i class="true"><FontAwesome icon="check-circle"/></i>Lowest interest rates</p>
    </div>
  </div>
  <div class="timeline">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-7">
          <div class="content">
            <h2>Let us help you find the best mortgage options</h2>
            <small>Who is it for :</small>
            <ul>
              <li>For residents and non-residents</li>
              <li>Buying your first home</li>
              <li>Mortgage loans for handover payments</li>
              <li>Switch your current mortgage for a lower interest rate</li>
              <li>Equity release to unlock cash</li>
            </ul>
          </div>
        </div>
        <div class="col-md-5 p-0">
          <div class="image">
            <img class="img" src="../assets/images/mortgage-1.jpg" alt="Capex Properties Real Estate Agency">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid we-do">
    <h2>What we do</h2>
    <div class="row">
      <div class="col-md-4 mb-2">
        <div class="box">
          <i><FontAwesome icon="handshake" /></i>
          <h3>Getting to know you</h3>
          <p>We need to know a bit about you and what you are looking for.</p>
        </div>
      </div>
      <div class="col-md-4 mb-2">
        <div class="box">
          <i><FontAwesome icon="chart-line" /></i>
          <h3>Comparing the whole market</h3>
          <p>We’re partnered up with all the banks so we’ll compare the market and offer you the best deal based on you requirements.</p>
        </div>
      </div>
      <div class="col-md-4 mb-2">
        <div class="box">
          <i><FontAwesome icon="tasks" /></i>
          <h3>Advise and apply</h3>
          <p>We’ll take care of it all! The paperwork, the application, the bank follow ups, we’ll handle all the stress and support you through to final disbursal.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid why-us">
    <div class="row">
      <div class="col-4">
        <h3 class="color-main">Why Choose Capex Properties</h3>
      </div>
      <div class="col-4 text-center">
        <h3 class="color-main">Capex Properties</h3>
      </div>
      <div class="col-4 text-center">
        <h3>Traditional Banks</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <p>Match you with the right lender based on you profile and requirements</p>
      </div>
      <div class="col-4 text-center">
        <i class="check"><FontAwesome icon="check-circle"/></i>
      </div>
      <div class="col-4 text-center">
        <i><FontAwesome icon="xmark-circle"/></i>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <p>End to end service from your initial call to final disbursal</p>
      </div>
      <div class="col-4 text-center">
        <i class="check"><FontAwesome icon="check-circle"/></i>
      </div>
      <div class="col-4 text-center">
        <i><FontAwesome icon="xmark-circle"/></i>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <p>Manage all the paperwork and communication to provide you a hassle free experience</p>
      </div>
      <div class="col-4 text-center">
        <i class="check"><FontAwesome icon="check-circle"/></i>
      </div>
      <div class="col-4 text-center">
        <i><FontAwesome icon="xmark-circle"/></i>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-5 percent">
    <div class="row">
      <div class="col-md-6">
        <div class="content">
          <h3>UAE Resident</h3>
          <p>First time resident buyers can avail up to 85% funding of the property value</p>
          <div class="image">
            <img src="../assets/images/mortgage-85.png" alt="Capex Properties Real Estate Agency" class="img">
          </div>
          <button @click="openModal()">FREE CONSULTATION</button>
        </div>
      </div>
      <div class="col-md-6">
        <div class="content">
          <h3>Non-Resident</h3>
          <p>Non-resident investors can avail up to 60% funding of the property value</p>
          <div class="image">
            <img src="../assets/images/mortgage-60.png" alt="Capex Properties Real Estate Agency" class="img">
          </div>
          <button @click="openModal()">FREE CONSULTATION</button>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-5 information">
    <div class="row py-5">
      <div class="col-md-6 content">
        <h5>WE DON’T CHARGE A FEE</h5>
        <h2>Highly experienced team of Mortgage Advisors</h2>
        <p>Whatever your requirement may be, we can provide the support and expertise you need. All our advisors have years of experience and have helped hundreds of people just like you.</p>
        <button @click="openModal()">FREE CONSULTATION</button>
      </div>
      <div class="col-md-6">
        <div class="image">
          <img class="img" src="../assets/images/mortgage-3.jpg" alt="Capex Properties Real Estate Agency">
        </div>
      </div>
    </div>
    <div class="row py-5">
      <div class="col-md-6">
        <div class="image">
          <img class="img" src="../assets/images/mortgage-4.jpg" alt="Capex Properties Real Estate Agency">
        </div>
      </div>
      <div class="col-md-6 content">
        <h5>COMPARE THE WHOLE MARKET</h5>
        <h2>We’ll check 30+ banks</h2>
        <p>We’re not tied to one lender, we understand policies and compare the whole market to offer you the best deal.</p>
        <button @click="openModal()">FREE CONSULTATION</button>
      </div>
    </div>
    <div class="row py-5">
      <div class="col-md-6 content">
        <h5>WE’LL FIND YOU THE BEST DEALS</h5>
        <h2>The best rates available in the UAE</h2>
        <p>We know the market well and understand the policies, we offer you the best rates based on your profile and requirements</p>
        <button @click="openModal()">SEE WHAT YOU QUALIFY FOR</button>
      </div>
      <div class="col-md-6">
        <div class="image">
          <img class="img" src="../assets/images/mortgage-5.jpg" alt="Capex Properties Real Estate Agency">
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-5 contact-us">
    <div class="row">
      <div class="col-md-6 p-0">
        <div class="content">
          <h5>FREE CONSULTATION</h5>
          <h3>Get your free mortgage and home loan consultation</h3>
          <div>
            <p class="main-p">We’ll help you answer the following questions:</p>
            <p class="m-0"><i class="true"><FontAwesome icon="check-circle"/></i>How much you can borrow</p>
            <p class="m-0"><i class="true"><FontAwesome icon="check-circle"/></i>Fixed or variable rates</p>
            <p class="m-0"><i class="true"><FontAwesome icon="check-circle"/></i>Your monthly installment</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 p-0">
        <div class="form-box">
          <MortgageForm subject="Message from Website, Mortgage"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import isMobile from '@/services/isMobile'
import MortgageModal from "../components/MortgageModal.vue"
import MortgageForm from "../components/MortgageForm.vue"
import { ref } from '@vue/reactivity'
import { useHead } from '@vueuse/head'
export default {
  components: {
    MortgageModal,
    MortgageForm
  },
  setup() {

    useHead({
      meta: [
        {
          name: `description`,
          content: `Working with a range of UAE banks, Capex Properties guarantee their clients the most suitable mortgage for their individual circumstances. Our experts can help you find the best mortgage offers in Dubai and UAE.`
        },
        {
          property: `og:title`,
          content: `Mortgage | ${process.env.VUE_APP_BASE_TITLE}`
        },
        {
          property: `og:description`,
          content: `Working with a range of UAE banks, Capex Properties guarantee their clients the most suitable mortgage for their individual circumstances. Our experts can help you find the best mortgage offers in Dubai and UAE.`
        },
        {
          property: `og:url`,
          content: `https://capexproperties.ae/mortgage`
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:site_name`,
          content: process.env.VUE_APP_BASE_TITLE
        },
      ],
      link: [
        {
          rel: `canonical`,
          href: `https://capexproperties.ae/mortgage`
        }
      ]
    })
    
    const modalActive = ref(false)
    
    const openModal = () => {
      modalActive.value = true
    }
    const closeModal = () => {
      modalActive.value = false
    }

    return{isMobile, modalActive, openModal, closeModal}
  }
}
</script>

<style scoped>
.mortgage-header {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url('@/assets/images/mortgage-header.jpg');
  background-color: #cccccc;
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.mortgage-header::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
}
.mortgage-header > * {
  z-index: 1;
}
.mortgage-header .content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
}
.mortgage-header h1 {
  font-size: 3rem;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.mortgage-header p {
  color: #ffffff;
  text-align: center;
  text-transform: capitalize;
}
.mortgage-header button, .information button, .percent button {
  background-color: var(--mainColor);
  color: #ffffff;
  border: none;
  outline: none;
  border-radius: 2px;
  padding: 5px 10px;
  margin-bottom: 15px;
}
.under-mortgage-header {
  display: flex;
  gap: 5px;
  background-color: rgba(0, 0, 0, .6);
  color: #ffffff;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}
.under-mortgage-header p {
  margin: 0;
}
.under-mortgage-header i, .contact-us .content i {
  color: var(--mainColor);
  margin-right: 5px;

}
.timeline .content {
  margin: 5rem 0;
  padding-left: 3rem;
}
.timeline h2, .we-do h2, .why-us h3 {
  text-transform: capitalize;
  font-weight: 900;
}
.timeline ul {
  border-left: 1px solid var(--mainColor);
  padding: 0;
  margin-top: 10px;
}
.timeline ul li {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 2rem;
}
.timeline ul li::before {
  content: '';
  position: absolute;
  top: 1.5px;
  left: -11px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: var(--mainColor);
}
.timeline .image, .information .image {
  height: 100%;
}
.we-do {
  background-color: var(--mainColor);
  padding: 5rem 3rem;
}
.we-do h2 {
  color: #ffffff;
  text-align: center;
}
.we-do .box {
  background-color: #ffffff;
  color: var(--mainColor);
  padding: 1rem;
  text-align: center;
  height: 100%;
}
.we-do .box h3 {
  font-size: 1.3rem;
  font-weight: 900;
}
.we-do .box i {
  font-size: 2rem;
}
.why-us {
  padding: 3rem;
}
.why-us h3 {
  font-size: 1.5rem;
}
.why-us i {
  font-size: 1.5rem;
  color: #dddddd;
}
.why-us i.check {
  color: var(--mainColor);
}
.percent {
  padding: 0 3rem;
}
.percent .content {
  border-top: 3px solid var(--mainColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}
.percent .content img {
    width: 300px;
  }
.percent .content h3 {
  font-weight: bold;
  color: var(--mainColor);
}
.percent .content p {
  text-align: center;
}
.information {
  padding: 0 3rem;
}
.information .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.contact-us {
  background-image: url('@/assets/images/mortgage-2.jpg');
  background-color: #cccccc;
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 3rem;
}
.contact-us .content {
  background-color: rgba(36, 36, 36, 0.7);
  height: 100%;
  color: #ffffff;
  padding:  2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}
.contact-us .content h5, .information h5 {
  color: var(--mainColor);
  font-size: 1rem;
  font-weight: bold;
  margin: 10px 0;
}
.contact-us .content h3, .information h2 {
  font-weight: bold;
    text-transform: capitalize;
    font-size: 2.5rem;
}
.contact-us .content .main-p {
  font-weight: bold;
}
.contact-us .form-box {
  padding: 2rem;
  background-color: #ffffff;
}

@media (max-width: 767.98px) { 
  .mortgage-header .title {
    font-size: 2.5rem;
  }
  .under-mortgage-header {
    flex-direction: column;
    padding: 2rem 5px;
  }
  .mortgage-header .content {
    justify-content: end;
    padding-bottom: 3rem;
  }
  .mortgage-header .content p {
    font-size: .7rem;
  }
  .under-mortgage-header p {
    margin-bottom: 1rem;
  }
  .we-do {
    padding: 3rem 1rem;
  }
  .timeline .content {
    padding: 0 1rem;
  }
  .why-us {
    padding: 3rem 1rem;
  }
  .percent {
    padding: 0 1rem;
  }
  .information {
    padding: 0 1rem;
  }
  .contact-us {
    padding: 3rem 1.7rem;
  }
  .contact-us .form-box {
    padding: 1rem;
  }
}
@media (max-width: 575.98px) {
  .mortgage-header .content {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .mortgage-header .title {
    font-size: 2rem;
  }
  .why-us h3 {
    font-size: 1rem;
  }
  .why-us p {
    font-size: .8rem;
  }
  .why-us i {
    font-size: 1rem;
  }
  .information h2 {
    font-size: 2rem;
  }
}
</style>