<template>
  <div class="container all-proj">
    <div class="row">
      <h2 class="text-center page-title">PROJECTS</h2>
    </div>
    <div class="filter">
      <p  @click="toggleFilter" :class="{active: showFilter}"><i><FontAwesome icon="filter" /></i> Filter <i class="arrow"><FontAwesome icon="chevron-right" /></i></p>
      <span v-if="projectName">Project: {{projectName}}</span>
      <span v-if="developer">Developer: {{developer.replaceAll('-',' ')}}</span>
      <span v-if="area">Area: {{area.replaceAll('-',' ')}}</span>
      <span v-if="type">{{type}}</span>
      <span v-if="sort">Sort By: {{sort.replaceAll('_',': ')}}</span>
      <span @click="clearAll" v-if="projectName || developer || area || type || sort">X Clear All</span>
    </div>
    <form class="row" :class="{show : showFilter}" @submit.prevent>
      <div class="mb-4 col-md-4">
        <label for="projectName" class="form-label">Search Projects</label>
        <input id="projectName" class="form-control" type="search" placeholder="Enter Project or Developer..." v-model="projectName" @change="changeProjectName" />
      </div>
      <div class="mb-4 col-md-4">
        <label for="type" class="form-label">Select Type</label>
        <select id="type" class="form-select" v-model="type" @change="changeType">
          <option value="">All</option>
          <option value="off-plan">Off-Plan Projects</option>
          <option value="ready">Ready Projects</option>
        </select>
      </div>
      <div class="mb-4 col-md-4">
        <label for="developer" class="form-label">Select Developer</label>
        <select id="developer" class="form-select" v-model="developer" @change="changeDeveloper">
          <option value="">All</option>
          <template v-if="developers">
            <option v-for="developer in developers" :key="developer.id" :value="developer.slug">{{developer.name}}</option>
          </template>
        </select>
      </div>
      <div class="mb-4 col-md-4">
        <label for="area" class="form-label">Select Area</label>
        <select id="area" class="form-select" v-model="area" @change="changeArea">
          <option value="">All</option>
          <template v-if="areas">
            <option v-for="area in areas" :key="area.id" :value="area.slug">{{area.name}}</option>
          </template>
        </select>
      </div>
      <div class="mb-4 col-md-4">
        <label for="sort" class="form-label">Sort By</label>
        <select id="sort" class="form-select" v-model="sort" @change="changeSort">
          <option value="">Default</option>
          <option value="price_high">Start Price: Highest</option>
          <option value="price_low">Start Price: Lowest</option>
        </select>
      </div>
    </form>
    <div class="row" v-if="projects">
      <ProjectBox :projects="projects" :imgUrl="imgUrl" />
      <ThePagination :mainMeta="mainMeta" :queries="queries" />
    </div>
    <div class="row" v-else-if="projError">
      <div class="col-12">
        <h2 class="error">An Error Happened!</h2>
      </div>
    </div>
    <div class="row" v-else>
      <BoxLoading />
      <BoxLoading />
      <BoxLoading />
      <BoxLoading />
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useRoute, useRouter } from 'vue-router'
import { watch } from '@vue/runtime-core'
import getProjects from '@/services/projects/getProjects'
import getDevelopers from '@/services/developers/getDevelopers'
import getAreas from '@/services/areas/getAreas'
import ProjectBox from '@/components/ProjectBox.vue'
import ThePagination from '@/components/ThePagination.vue'
import BoxLoading from "@/components/BoxLoading.vue"
import { useHead } from '@vueuse/head'
export default {
  components: {
    ProjectBox,
    BoxLoading,
    ThePagination
  },
  setup() {

    const route = useRoute()
    const router = useRouter()
    
    const queries = ref(route.query)

    useHead({
      meta: [
        {
          name: `description`,
          content: `Get all the information you need to know about the latest Projects - Developments in Dubai, Abu Dhabi, Sharjah, UAE. With Capex Properties, you can find all the details related to New Launches, Off Plan projects, Under Construction and Ready to Move Projects - Developments.`
        },
        {
          property: `og:title`,
          content: `${route.meta.title}`
        },
        {
          property: `og:description`,
          content: `Get all the information you need to know about the latest Projects - Developments in Dubai, Abu Dhabi, Sharjah, UAE. With Capex Properties, you can find all the details related to New Launches, Off Plan projects, Under Construction and Ready to Move Projects - Developments.`
        },
        {
          property: `og:url`,
          content: `https://capexproperties.ae${route.fullPath}`
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:site_name`,
          content: process.env.VUE_APP_BASE_TITLE
        },
      ],
      link: [
        {
          rel: `canonical`,
          href: `https://capexproperties.ae${route.fullPath}`
        }
      ]
    })

    
    const { mainData:developers, mainError:devError, loadData:loadDevelopers } = getDevelopers()
    loadDevelopers()
    
    const { mainData:areas, mainError:areError, loadData:loadAreas } = getAreas()
    loadAreas()

    const { mainData:projects, mainError:projError, mainMeta, loadData:loadProjects } = getProjects()
    loadProjects(queries.value)
    
    const imgUrl = process.env.VUE_APP_BASE_URL

    const showFilter = ref(false)

    const projectName = ref('')
    const developer = ref('')
    const type = ref('')
    const area = ref('')
    const sort = ref('')

    if(route.query.search)
    {
      projectName.value = route.query.search
    }
    const changeProjectName = () => {
      router.push({ path: '/projects', query: {search: projectName.value, developer: developer.value, type: type.value, area: area.value, sort: sort.value} })
    }

    if(route.query.type)
    {
      type.value = route.query.type
    }
    const changeType = () => {
      router.push({ path: '/projects', query: {search: projectName.value, developer: developer.value, type: type.value, area: area.value, sort: sort.value} })
    }

    if(route.query.developer)
    {
      developer.value = route.query.developer
    }
    const changeDeveloper = () => {
      router.push({ path: '/projects', query: {search: projectName.value, developer: developer.value, type: type.value, area: area.value, sort: sort.value} })
    }

    if(route.query.area)
    {
      area.value = route.query.area
    }
    const changeArea = () => {
      router.push({ path: '/projects', query: {search: projectName.value, developer: developer.value, type: type.value, area: area.value, sort: sort.value} })
    }

    if(route.query.sort)
    {
      sort.value = route.query.sort
    }
    const changeSort = () => {
      router.push({  path: '/projects', query: {search: projectName.value, developer: developer.value, type: type.value, area: area.value, sort: sort.value} })
    }

    watch(() => route.query, newQuery => {
      queries.value = newQuery
      projectName.value = newQuery.search ? newQuery.search : ''
      type.value = newQuery.type ? newQuery.type : ''
      developer.value = newQuery.developer ? newQuery.developer : ''
      area.value = newQuery.area ? newQuery.area : ''
      sort.value = newQuery.sort ? newQuery.sort : ''
      projects.value = null
      loadProjects(queries.value)
    })

    const toggleFilter = () => {
      showFilter.value = !showFilter.value
    }

    const clearAll = () => {
      showFilter.value = false
      router.push({ path: '/projects'})
    }

    return {
      projects,
      developers,
      areas,
      projError,
      devError,
      areError,
      imgUrl,
      projectName,
      changeProjectName,
      type,
      changeType,
      developer,
      changeDeveloper,
      area,
      changeArea,
      sort,
      changeSort,
      mainMeta,
      queries,
      showFilter,
      toggleFilter,
      clearAll
    }
  }
}
</script>

<style scoped>
.all-proj {
  min-height: 30rem;
  margin-top: 10rem;
}
.page-title {
  font-weight: bold;
}
.form-control, .form-select {
  border-color: var(--mainColor);
}
.form-control:active, .form-control:focus, .form-select:active, .form-select:focus {
  box-shadow: none;
  border-color: var(--mainColor);
}
select, select option {
  text-transform: capitalize;
}
.filter {
  display: none;
}
.filter p {
  font-weight: bold;
  cursor: pointer;
}
.filter span {
  background-color: #eee;
  border-radius: 1rem;
  font-size: .7rem;
  text-transform: capitalize;
  margin-right: .5rem;
  margin-bottom: 1rem;
  padding: .2rem .4rem;
}
.filter p.active {
  color: var(--mainColor);
}
.filter p i.arrow {
  font-size: .8rem;
}
.filter p i.arrow svg {
  transition: all ease-in-out .1s;
}
.filter p.active i.arrow svg {
  transform: rotate(90deg);
}
@media (max-width: 767.98px) {
  .filter {
    display: block;
  }

  form {
    display: none;
    margin-top: 1rem;
  }

  form.show {
    display: block;
  }
}

@media (max-width: 575.98px) {
  .all-proj {
    margin-top: 6rem;
  }
}
</style>
