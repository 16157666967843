<template>
  <div class="container mt-5 partners">
    <div class="row">
      <div class="col-12">
        <h2>OUR PARTNERS</h2>
      </div>
      <div class="col-12">
        
        <TheCarousel :images="images" :autoplay="2000" alt="partners for capex properties real estate agency in dubai" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'

import TheCarousel from '../../components/TheCarousel.vue'
export default {
  components: {
    TheCarousel
  },
  setup() {
    const images = ref(['logo1.jpg','logo2.jpg','logo3.jpg','logo4.jpg','logo5.jpg','logo6.jpg','logo7.jpg','logo8.jpg','logo9.jpg','logo10.jpg','logo11.jpg','logo12.jpg','logo13.jpg','logo14.jpg','logo15.jpg','logo16.jpg','logo17.jpg','logo18.jpg','logo19.jpg'])
    return{ images}
  }}
</script>

<style scoped>
h2 {
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
}
img {
  width: 11.5rem;
}
@media (max-width: 575.98px) { 
  img {
    width: 8.5rem;
  }
}
</style>