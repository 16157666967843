<template>
  <div class="form-box">
    <h2>{{title}}</h2>
    <form @submit.prevent="submitForm(projectName)">
      <input
        required
        type="text"
        v-model="messageData.fullName"
        id="nameInput"
        placeholder="Full Name *"
      />
      <input
        required
        type="email"
        v-model="messageData.email"
        id="emailInput"
        placeholder="Email Address *"
      />
      <!-- <input
        required
        type="tel"
        v-model="messageData.phone"
        id="phoneInput"
        placeholder="Phone Number *"
      /> -->
      
      <vue-tel-input v-model="messageData.phone" :value="messageData.phone" v-bind="bindProps" @input="onInput"></vue-tel-input>
      <button type="submit">{{buttonTitle}}</button>
      <p class="text-center text-white message-status">{{ messageStatus }}</p>
    </form>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import axios from 'axios'
const queryString = require('query-string')
import { VueTelInput } from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';
export default {
  props: ['projectName', 'title', 'buttonTitle', 'isDownload'],
  components: {
    VueTelInput,
  },
  setup(props, {emit}) {
    
    const download = () => {
      emit('download')
    }

    const msgUrl = process.env.VUE_APP_MESSAGE_URL

    const messageData = ref({
      'fullName': '',
      'email': '',
      'phone': '',
      'code': '',
      'message': '',
      'subject' : 'Email from Capex Website'
    })
    const messageStatus = ref('')

    const bindProps = {
      mode: 'international',
      preferredCountries: ['AE'],
      inputOptions: {
        required: true,
        placeholder: 'Enter a Phone Number',
        type: 'tel',
        autocomplete: 'on',
        maxlength: 25,
        name: 'phone',
        tabindex: 0,
        id: 'phoneInput',
      }
    }

    const onInput = (phone, phoneObject) => {
      if (phoneObject?.formatted) {
        messageData.value.phone = phoneObject.formatted
        messageData.value.code = phoneObject.countryCode
      }
    }

    const submitForm = async (name) => {
      if(props.isDownload) {
        messageData.value.message = `Hi I'm here to ${props.title} for ${name} project.`
      } else {
        messageData.value.message = `Hi I'm interested in ${name} project.`
      }
      messageStatus.value = 'Sending...'
      try {
          let response = await axios.post(
          `${msgUrl}/message.php`,
          queryString.stringify(messageData.value),
          { 
            'Accept': 'application/json', 
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        )
          if (response) {
            messageStatus.value = response.data;
            if (response.data === "Message was sent successfully") {
              window.gtag('event', 'conversion', {'send_to': 'AW-11153917494/pf4CCLvVsZkYELaMzcYp'})
              if(props.isDownload) {
                messageStatus.value = 'Downloading...'
                download()
              }
              setTimeout(() => {
                messageStatus.value = "";
                messageData.value.fullName = "";
                messageData.value.phone = "";
                messageData.value.code = "";
                messageData.value.email = "";
                messageData.value.message = "";
              }, 1500);
            }
          } else {
            messageStatus.value = "Something went wrong!";
          }
      } catch (err) {
        alert('Something Went Wrong!')
        messageStatus.value = err.message
      }
    }
    return{
      messageStatus,
      messageData,
      bindProps,
      onInput,
      submitForm,
    }
  }

}
</script>

<style scoped>
  
.form-box {
  padding: 2rem;
  background-color: rgba(20, 20, 20, 0.7);
}
.form-box h2 {
  text-align: center;
  color: var(--mainColor);
}
.form-box form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
form input {
  outline: 0;
}
form button {
  background-color: var(--mainColor);
  color: #ffffff;
}
</style>